<template>
      <v-card
          class="pb-5 mx-auto"
          max-width="600"
          elevation="6"
      >
        <v-img
            height="250"
            src="https://cdn.pixabay.com/photo/2019/10/02/04/40/registration-4519979_1280.jpg"
        ></v-img>
        <v-card-title>
          Registration successful!
        </v-card-title>
        <v-card-subtitle>
          Please confirm your email address by clicking the Link we just sent to<br><span v-html="email"></span>
        </v-card-subtitle>
        <v-card-actions>
          <v-btn
              outlined
              rounded
              text
          >
            resend email
          </v-btn>
        </v-card-actions>

      </v-card>
</template>

<script>
    export default {
        name: "RegisterVerification",
        computed:{
            email(){
                return this.$route.query.email;
            }
        }
    }
</script>

<style scoped>

</style>